import React from 'react';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import styled from '@emotion/styled';
import { breakpoints, wrapper } from '../../utils/style';
import WithdrawCommentsStaticLoadmore from '../../components/Content/Withdraw/Comments/CommentsStaticLoadmore';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import PlayForFreeGames from '../../components/Content/Freespin/PlayForFreeGame';
import FreespinGame from '../../components/Content/Freespin/FreespinGame';
import Parallax from '../../components/shared/Backgrounds/Parallax';

const WrapperDesktop = styled('div')`
  ${wrapper}
  .h1 {
    margin-bottom: 50px;
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0 15px;
    .h1 {
      margin-bottom: 20px;
    }
  }
`;

const Game = ({ pageContext }) => {
  const breadcrumbs = pageContext.breadcrumbs;
  const pageTitle = pageContext.page_title;
  const gameName = pageContext.name;
  const gameCode = pageContext.code;
  return (
    <Layout>
      <SEO
        title={gameName}
        description={gameName}
        pathname={'/' + gameCode + '/'}
        breadcrumbs={breadcrumbs}
      />
      <Parallax />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={pageTitle} />
        <div className="h1 a-center gradient">Free Spin Games</div>
        <FreespinGame h1={'เกม ' + gameName} gameCode={gameCode}/>
      </WrapperDesktop>
      <WrapperDesktop>
        <PlayForFreeGames exclCode={gameCode} />
      </WrapperDesktop>
      <WrapperDesktop>
        <WithdrawCommentsStaticLoadmore />
      </WrapperDesktop>
    </Layout>
  );
};

export default Game;
