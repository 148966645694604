import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { color, breakpoints } from '../../../utils/style';
import { getAuthToken, Logout } from '../../Auth/Utils';
import IcomoonIcon from '../../shared/IcomoonIcon';
import { getAuthHeader } from '../../Auth/Utils';
import PropTypes from 'prop-types';
import { freespinGameIframeUrl } from './Config';


const Wrapper = styled('div')`
  .errorMsg {
    background: ${color.red};
    color: ${color.white};
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
  }
  .green-button {
    margin: 10px 0;
    svg {
      margin-right: 7px;
    }
  }
  .iframe-container-game {
    position: relative;
    overflow: hidden;
    padding-top: 74.025%;
    clear: both;
    border-radius: 20px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }

  .gatsby-image-wrapper {
    border-radius: 10px;
  }

  .button-banner-block {
    position: relative;
    &:hover {
      .button-banner-hover {
        display: flex;
      }
    }
  }
  
  .button-banner-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    background: rgba(0,0,0,0.37);
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 10px;
  }

  @media (min-width: ${breakpoints.mdMin}) and (max-width: ${breakpoints.xl}) {
    padding: 0 10%;
  }

  .mobile-portrait {
    display: none;
    text-align: center;
    position: relative;
    .gatsby-image-wrapper, img {
      border-radius: 10px;
    }
    .rotate-icon-container {
      display: inline-block;
      text-align: center;
    }
    p {
      margin: 15px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    .mobile-landscape, .loading {
      display: none;
      opacity: 0;
      transition: all 1s linear;
    }
    .mobile-portrait{
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;
      background: ${color.white};
      border: 1px solid ${color.red};
      border-radius: 10px;
    }
    .button-banner-block {
      .button-banner-hover {
        display: flex;
      }
    }
    .iframe-container-game {
      display: none;
    }
  }
  
  @media (orientation: landscape) and (max-width: ${breakpoints.md}) {
    .mobile-landscape, .loading {
      display: block;
      opacity: 1;
    }
    .mobile-fixed-wrap {
      position: fixed;
      left:0;
      top:0;
      z-index: 1000;
      padding: 0;
      background: black;
      width: 100vw;
      height: 100vh;
      overflow-y: auto;
    }
    .iframe-container-game {
      display: block;
      position: static;
      padding-top:0;
      height: 100%;
      iframe {
        position: static;
      }
    }
    
    .mobile-portrait {
      display: none;
    }
  }
  
  /* This one is to prevent triggering landscape mode when keyboard is open on android */
  @media screen and (max-device-aspect-ratio: 1/1), (max-aspect-ratio: 1/1){
    .android {
      .mobile-landscape, .loading {
        display: none;
        opacity: 0;
        transition: all 1s linear;
      }
      .mobile-portrait{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        background: ${color.white};
       border: 1px solid ${color.red};
        border-radius: 10px;
      }
      .button-banner-block {
        .button-banner-hover {
          display: flex;
        }
      }
      .iframe-container-game {
        display: none;
      }
    }
  }
  
`;

const getFreespinIframeUrl = async (game_code, setFreespinGameurl, setFreespinGameError) => {
  if(!getAuthToken){
    return false;
  }
  let body = JSON.stringify({code: game_code});

  const response = await fetch(freespinGameIframeUrl, {
    method: 'POST',
    body: body,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getAuthHeader()
    },
  });
  if (response.ok) {
    let json = await response.json();
    if (json.error && json.message) {
      //console.log(json.error);
      setFreespinGameError(json.message);
      if(json.error_type === 'session_expired'){
        console.log('Call Logout from Iframe');
        Logout(false, setUsernameCallback);
      }
    } else {
      setFreespinGameurl(json.url);
    }
  } else {
    console.log("Game Iframe Error HTTP: " + response.status);
    let json = await response.json();
    if(json.code === 'jwt_auth_invalid_token'){
      Logout(false, setUsernameCallback);
    }
  }
};

let setUsernameCallback = false;

const GameIframeLogged = ( { username, gameCode } ) => {
  const [freespinGameUrl, setFreespinGameUrl] = useState(false);
  const [freespinGameError, setFreespinGameError] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (username) {
      //getLotteryIframeUrl(setFsGameUrl);
      getFreespinIframeUrl(gameCode, setFreespinGameUrl, setFreespinGameError);
    }
    /* adding custom class to fix Android keyboard issue */
    if (navigator.userAgent.indexOf( "Android" ) !== -1) {
      const loggedInUser = document.getElementById('logged-in-user');
      loggedInUser.classList.add('android');
    }
  }, [username, gameCode]);

  return (
    <Wrapper id="game-page-wrap">
      {username && (
        <div>
          <div className="logged-in-user" id="logged-in-user">
            {freespinGameUrl ? (
              <div className="mobile-landscape">
                <div className="mobile-fixed-wrap">
                  <div className="iframe-container-game" id="iframe-container-game">
                    <iframe title={gameCode + " Game"} scrolling={'no'} src={freespinGameUrl} />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                {freespinGameError ? <div id="fs-frame-error" className="errorMsg fs-frame-error">{freespinGameError}</div> :
                  <div className="loading">กำลังโหลด ...</div>
                }
              </div>
            )}
          </div>
          <div className="mobile-portrait">
            <div className="rotate-icon-container">
              <IcomoonIcon
                icon="mobile-rotate"
                color={color.orange}
                size={80}
              />
              <p>กรุณาหมุนอุปกรณ์ของคุณ</p>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default GameIframeLogged;

GameIframeLogged.propTypes = {
  username: PropTypes.string,
};
