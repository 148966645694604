import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';

const BannerWrapper = styled.div`
  margin-bottom: 20px;
`;

const NotLoggedInBanner = () => (
  <StaticQuery
    query=
      {graphql`
        query {
          bg: file(relativePath: { eq: "freespin/freespin-pre-login.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1170) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
    render={data => (
      <BannerWrapper>
        {data.bg.childImageSharp.fluid &&
        <Img alt="Log in to play freespins!" fluid={data.bg.childImageSharp.fluid} fadeIn={false} loading='eager' />
        }
      </BannerWrapper>
    )}
  />
);

export default NotLoggedInBanner;
