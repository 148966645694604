import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/style';
import { CustomLoginButton } from '../../../components/Auth/Auth';
import NotLoggedInBanner from './NotLoggedInBanner';

const Wrapper = styled('div')`
  .gatsby-image-wrapper {
    border-radius: 10px;
  }

  .button-banner-block {
    position: relative;
    &:hover {
      .button-banner-hover {
        display: flex;
      }
    }
  }
  
  .button-banner-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    background: rgba(0,0,0,0.37);
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 10px;
  }
  
  .not-logged-in {
    margin-top: 10px;
  }

  @media (min-width: ${breakpoints.mdMin}) and (max-width: ${breakpoints.xl}) {
    padding: 0 10%;
  }

  @media (max-width: ${breakpoints.md}) {
    .not-logged-in {
      margin-top: 0;
    }
  }
  
`;

const GameIframeNotLogged = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Wrapper id="game-page-wrap">
      <div className="not-logged-in button-banner-block">
        <div className="not-logged-in-hover button-banner-hover">
          <CustomLoginButton text={'เล่นเลย'}/>
        </div>
        <NotLoggedInBanner/>
      </div>
    </Wrapper>
  );
};

export default GameIframeNotLogged;

