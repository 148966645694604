import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { breakpoints, font } from '../../../utils/style';
import { AuthFreespinLogged, AuthFreespinNotLogged, getUserInformation } from '../../../components/Auth/Auth';
import GameIframeNotLogged from './IframeNotLogged';
import GameIframeLogged from './IframeLogged';
import StoreContext from '../../shared/Context/StoreContext';


const GameWrapper = styled('div')`
  
  .top-header {
    display: flex;
    flex-flow: row-wrap;
    justify-content: space-between;
  }
  h1 {
    width: calc(100% - 500px);
    display: inline-block;
    vertical-align: top;
    margin-top: 0;
    font-size: 24px;
    font-weight: ${font.boldWeight};
  }
  .auth-wrapper {
    margin: 0;
    width: auto;
  }
  .auth-wrapper-logged {
    margin-bottom: 20px;
  }
  
  @media (max-width: ${breakpoints.md}) {
    .auth-wrapper-not-logged {
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .mobile-reverse {
      flex-direction: column-reverse;
    }
  
    h1 {
      font-size: 20px;
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }
    
    .auth-wrapper {
      padding: 10px 0 0;
    }
    
    .buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .button {
        flex-basis: 47%;
        margin: 0;
      }
    }
  }
`;

const FreespinGame = ({ h1, gameCode }) => {
  const value = useContext(StoreContext);
  const contextUsername = value.username;
  const [loadingGame, setLoadingGame] = useState(true);
  const [userInformation, setUserInformation] = useState({});
  /* useEffect to prevent blinking of username-related stuff */
  useEffect(() => {
    let mounted = true;
    getUserInformation().then(data => {
      if (mounted) {
        setUserInformation(data);
        setLoadingGame(false);
      }
    });
    return () => {
      mounted = false;
    }
  }, [contextUsername]);
  return (
    <>
      {loadingGame ?
        <p>กำลังโหลด ...</p>
        : <>
          {userInformation.username ?
            <GameWrapper>
              <div className="top-header mobile-reverse">
                <h1 className="mustard-text">
                  <span className="game-title">{h1}</span>
                </h1>
                <AuthFreespinLogged username={userInformation.username}/>
              </div>
              <GameIframeLogged username={userInformation.username} gameCode={gameCode} />
            </GameWrapper> :
            <GameWrapper>
              <div className="top-header mobile-reverse">
                <h1 className="mustard-text">
                  <span className="game-title">{h1}</span>
                </h1>
                <AuthFreespinNotLogged />
              </div>
              <GameIframeNotLogged />
            </GameWrapper>
          }
        </>
      }
    </>
  );
};

export default FreespinGame;

FreespinGame.propTypes = {
  h1: PropTypes.string,
};

